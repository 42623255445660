<template>
  <div>
    <!-- <Upload action="/web/ky/provideOld/uploadFileForRelatives"  :show-upload-list="false"  :on-success="handleSuccess" :before-upload="handleBeforeUpload">
      <Button>点这里</Button>
    </Upload> -->
    <el-upload
      class="upload-demo"
      action="/web/ky/provideOld/uploadFileForRelatives"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "ImportRelativesFile",
  methods: {
    handleSuccess(res) {
      if (res.status === 0) {
        alert(res.info);
      } else {
        alert("导入失败");
      }
    },
    handleBeforeUpload(file) {
      if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        alert("请选择正确的文件");
      }
    },
    beforeAvatarUpload(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      console.log(extension);
      if (extension != "xlsx" || extension != "xls") {
      } else {
        alert("请选择正确的文件");
        return;
      }
    },
    handleAvatarSuccess(response) {
      console.log(response);
      if (response.status == 0) {
        alert("上传成功");
      }
    },
  },
};
</script>

<style scoped>
</style>

<template>
    <div>
      <div>
        <input type="text" v-model="contentMsg" />
        <button type="button" @click="websocketsend">发送消息</button>
      </div>
      <div>
        <textarea v-model="contentBox"></textarea>
      </div>
    </div>
</template>

<script>
export default {
  name: 'WsMessage',
  data () {
    return {
      websock: null,
      contentMsg: '',
      contentBox: ''
    }
  },
  created () {
    this.initWebSocket()
  },
  destroyed () {
    this.websock.close()
  },
  methods: {
    initWebSocket () {
      if ('WebSocket' in window) {
        // debugger
        var userId = Math.round(Math.random() * 10000)
        this.websock = new WebSocket('wss://h5endpoint.kangkt.com/ws/chat/qkb/room1/'.concat(userId).concat('/patient'))
        this.websock.onmessage = this.websocketonmessage
        this.websock.onopen = this.websocketonopen
        this.websock.onerror = this.websocketonerror
        this.websock.onclose = this.websocketclose
      } else {
        alert('当前浏览器 Not support websocket')
      }
    },
    websocketonopen () {
      this.websocketsend('链接已打开')
    },
    websocketonerror () {
      this.initWebSocket()
    },
    websocketonmessage (res) {
      this.contentBox = this.contentBox.concat('\n').concat(res.data)
    },
    websocketsend (Data) {
      this.websock.send(this.contentMsg)
      this.contentMsg = ''
    },
    websocketclose (e) {
      console.log('断开连接', e)
    }
  }
}
</script>

<style scoped>

</style>

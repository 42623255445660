import Vue from 'vue'
import VueRouter from 'vue-router'
import ImportFile from '../views/ImportFile'
import WsMessage from '../views/WsMessage'
import ImportOldFile from '../views/ImportOldFile'
import ImportRelativesFile from '../views/ImportRelativesFile'
import ImportExam from '../views/ImportExam'
import ImportNowExam from '../views/ImportNowExam'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'ImportFile',
    component: ImportFile
  },
  {
    path: '/importOldFile',
    name: 'ImportOldFile',
    component: ImportOldFile
  },
  {
    path: '/importRelativesFile',
    name: 'ImportRelativesFile',
    component: ImportRelativesFile
  },
  {
    path: '/importExam',
    name: 'ImportExam',
    component: ImportExam
  },
  {
    path: '/importNowExam',
    name: 'ImportNowExam',
    component: ImportNowExam
  },
  {
    path: '/wsMessage',
    name: 'WsMessage',
    component: WsMessage
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
